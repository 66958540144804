import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO';
import PasswordRecovery from '../../components/Login/PasswordRecovery';

const Forgot = ({ data }: any) => {
  return (
    <Layout>
      <SEO title="Forgot" />
      <PasswordRecovery />
    </Layout>
  );
};

export default Forgot;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
