import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../images/Registration/pluma.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '72px',
      backgroundColor: `#FFF`,
    },
    container: {
      display: 'flex',
      color: '#004F71',
      paddingLeft: `0%`,
      paddingRight: `0%`,
      height: 700,
      [theme.breakpoints.down(769)]: {
        height: 550,
      },
      '& h1': {
        textAlign: `center`,
        fontSize: 32,
        marginBottom: `50px`,
        [theme.breakpoints.down(501)]: {
          fontSize: 25,
          fontWeight: `600`,
        },
        [theme.breakpoints.down(375)]: {
          fontSize: 20,
        },
      },
      '& p': {
        [theme.breakpoints.down(501)]: {
          fontSize: 15,
        },
      },
      '& h2': {
        [theme.breakpoints.down(501)]: {
          fontSize: 18,
        },
      },
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0%`,
        paddingRight: `0%`,
        margin: `auto`,
      },
    },
    '@global': {
      body: {
        backgroundColor: theme.palette.common.white,
      },
      header: {
        display: `none`,
      },
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    feather: {
      float: `right`,
      width: `100%`,
      height: `100%`,
      backgroundImage: `url(${background})`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `right`,
      backgroundSize: `cover`,
      // backgroundColor:`#FF3`,
    },
    formGroup: {
      paddingTop: 55,
    },
    submitGroup: {
      '& button': {
        borderRadius: `30px`,
        backgroundColor: `#F8B133`,
        marginTop: 33,
        height: 48,
        fontSize: 18,
        fontWeight: `600`,
        textTransform: `capitalize`,
        '&:hover': {
          background: '#074F71',
          color: `#FFF`,
        },
      },
    },
  })
);
export default useStyles;
