import axios from 'axios';
import { initializeAxiosMockAdapter, isMockEnabled } from '../mock/mock.config';

const ApiAuth = axios.create({
  baseURL: process.env.GATSBY_MS_AUTH_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

if (isMockEnabled()) {
  initializeAxiosMockAdapter(ApiAuth);
}

export default ApiAuth;
